// VH Check
const test = vhCheck({
    cssVarName: 'vh-offset'
});

// Headroom
const header = document.getElementById("header");
const headroom = new Headroom(header, {
    offset: document.getElementById('header').offsetHeight
});
headroom.init();

// Reviews Carousel
var reviewsCarousel = new Swiper(".reviews__carousel", {
    speed: 300,
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
        768: {
            slidesPerView: 1.5,
        },
        992: {
            slidesPerView: 2.1,
        },
        1200: {
            slidesPerView: 2.5,
        },
        1500: {
            slidesPerView: 3.2,
        }
    }
});

//Burger menu toggle
document.querySelector('.header__burger').addEventListener('click', () => {
    document.querySelector('.header__nav').classList.toggle('open');
});

//Burger menu close
document.addEventListener('click', (e) => {
    if (!e.target.closest('.header__burger')) {
        document.querySelector('.header__nav').classList.remove('open');
    }
});


//Scroll up arrow
const scrollUp = document.getElementById("up");

if (scrollUp) {

    scrollUp.addEventListener("click", function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    function scrollUpToggle() {
        if (window.pageYOffset > 100) {
            if (window.oldScroll > window.scrollY) {
                scrollUp.classList.add("active");
            } else {
                scrollUp.classList.remove("active");
            }
        } else {
            scrollUp.classList.remove("active");
        }
        window.oldScroll = window.scrollY;
    }

    window.onscroll = function () {
        scrollUpToggle();
    };

}

//Add class active onClick ourdrivers
let items = document.getElementsByClassName("ourdrivers__item");
for (let i = 0; i < items.length; i++) {
    items[i].addEventListener("click", function () {
        (document.querySelector('.active')) ? document.querySelector('.active').classList.remove('active') : '';
        this.classList.adds('active');
    });
}

//Questions accordion
let acc = document.getElementsByClassName("questions__accordion__title");
let i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        let panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
            this.parentNode.classList.remove("open")
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
            this.parentNode.classList.add("open")
        }
    });
}

$(document).ready(function () {
    setTimeout(function () {
        $(".intro__inner__fade-out-title").animate({
            opacity: 0,
          }, 4000, function() {
          });
        }, 1500);
});
